import React, { useState , useEffect } from "react";
import { UserIcon, LockClosedIcon } from '@heroicons/react/outline';
import { Input , Button , Modal } from '../../components';
import Footer from '../../layout/Footer';
import BigLogo from '../../assets/img/BigLogo.svg';
import {LoginModal} from "@/pages/Login/services/modal";
import {UseLogin} from "@/pages/Login/services/Login";
import {Navigate} from "react-router-dom";
import {UnauthorizedError} from "@/utils/api";
import LoginToken from "@/pages/Login/methods/LoginToken";


//코드를 전역으로 빼서 써도 되는지 여쭤보기
const Login = () => {
    const {idPwModalOpen,ModalOpen,ModalClose} = LoginModal();
    let [id , setId] = useState('');
    let [pw, setPw] = useState('');
    const [roleSelect , setRoleSelect] = useState("");
    const {errorMsg,idCondition,pwCondition,code,LoginAuth} = UseLogin({id: id, pw: pw})
    const {host, resetToken, isCheckToken,navigate ,location, auth,cookie,login} = LoginToken();
    try {
        if(host === "" || host === null || host !== location ) {
            localStorage.setItem("host", location);
        }
        if (isCheckToken === false) {
            if(cookie === undefined) {
                // console.log(cookie,"cookie in auth Required");
                resetToken();
                auth.remove();
                // debugger;
                // return <Navigate to={login} replace/>
                // return navigate(login);
            }

        } else {
            console.count('마운트 해제 및 axios 요청 취소')
            // controller.abort()
            return <Navigate to={`/login/list`} replace/>
        }
    } catch (e){
        if(e instanceof UnauthorizedError) {
            if (isCheckToken === false) {
                localStorage.clear();
                resetToken();
                auth.remove();
                return <Navigate to={login} replace/>
                // return navigate(login);
            }
        }
    }
    return(
        <div className="loginRoot">
            <div className="loginPage">
                <form id="loginForm">
                    <div className="LoginFormGroup">
                        <div className="logoBox">
                            <img src={BigLogo}/>
                        </div>
                        <div className="inputRoot">
                            {/*<div className={"flex w-full gap-[7px]"}>*/}
                            {/*    <Button text={"점주"} size={"roleBtn"} value={"Admin"} onClick={(e)=>{setRoleSelect(e.target.value)}} isActive={roleSelect === "Admin"}/>*/}
                            {/*    <Button text={"직원"} size={"roleBtn"} value={"Staff"} onClick={(e)=>{setRoleSelect(e.target.value)}} isActive={roleSelect === "Staff"}/>*/}
                            {/*</div>*/}
                            <label className="inputWrap">
                                <Input icon={<UserIcon className={"absolute top-[14px] left-[22px]"}/>} id="id" name="id" size={"lg"} value={id} onchange={(e) => {setId(e.target.value);}} variant={`${idCondition ? "error": idCondition === undefined ? "" : code === "EIA001" ? "error" : "success"}`} placeholder="아이디를 입력하세요"/>
                            </label>
                            <label className="inputWrap">
                                <Input icon={<LockClosedIcon className={"absolute top-[14px] left-[22px]"}/>} id="pass" size={"lg"} name="pass" onSubmit={LoginAuth} value={pw} variant={`${pwCondition ? "error" : pwCondition === undefined ? "" : code === "EIA002" ? "error" : "success"}`} onchange={(e) => {setPw((e.target.value))}} type="password" placeholder="비밀번호를 입력하세요"/>
                            </label>
                            <div className="relative w-full h-[10px] ml-[5px]">
                                <p className="text-[#DC0E0E] absolute top-[50%] translate-y-[-50%] text-[10px]">{errorMsg}</p>
                            </div>
                            <Button text="로그인" onClick={LoginAuth} size={"xxl"}/>
                            <div className="LoginModal w-full">
                                <button type="button" className="text-[14px] textGreen"onClick={ ()=> ModalOpen() }>
                                    아이디·패스워드 찾기
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <Footer/>
            { idPwModalOpen &&
                <Modal
                    modalBtn={
                        <div className="w-full flex justify-center py-[10px]">
                            <div className="flex gap-[3.5px] w-[100px] justify-center">
                                <Button text="닫기" onClick={() => ModalClose()}/>
                            </div>
                        </div>
                    }
                    modalClose={ ModalClose }
                    modalTitle={'아이디·패스워드 찾기'}
                >
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                    when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                    It has survived not only five centuries, but also the leap into electronic typesetting,
                    remaining essentially unchanged.
                    It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
                    and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                </Modal>
            }
        </div>
    );
};

export default Login;