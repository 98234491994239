import AButton from "@/components/Button/AButton";
import React from "react";
import {ShopList} from "@/pages/Login/interface";

interface listType {
    list: ShopList
}

const ABtnList = ({list}: listType) => {
    const emptyArr: ShopList = {
        admins: [],
        staffs: []
    }
    if(list.staffs.length + list.admins.length < 6) {
        const arrList = () => {
            let arr : JSX.Element[] = [];
            for(let i = 0 ; i < (6 - (list.staffs.length + list.admins.length )) ; i++) {
                let aa = <AButton disabled domain={""} branch={""} key={`emptyArray${i}`}/>;
                arr.push(aa);
            }
            return arr;
        }
        return(
            <div className="inputRoot listRoot">
                {
                    list === emptyArr ? <>매장 리스트가 존재하지 않습니다.</> :
                        list.admins.length > 0 ? list.admins.map((item) => {
                            return (
                                <AButton domain={item.so} branch={item.shopBranch} key={item.so}/>
                            )
                        }) : <></>
                }
                {
                    list.staffs.length > 0 ? list.staffs.map((item) => {
                        return (
                            <AButton domain={item.so} branch={item.shopBranch} key={item.so}/>
                        )
                    }) : <></>
                }
                {
                    arrList()
                }
            </div>
        )
    } else if (list.staffs.length + list.admins.length > 6) {
        if(list.admins.length < 6) {
            const staffList = () => {
                let arr : JSX.Element[] = [];
                for(let i = 0 ; i < (6 - ( list.admins.length )) ; i++) {
                    let aa = <AButton domain={list.staffs[i].so} branch={list.staffs[i].shopBranch} key={`${i}`}/>;
                    arr.push(aa);
                }
                return arr;
            }
            return (
                <div className="inputRoot listRoot">
                    {
                        list === emptyArr ? <>매장 리스트가 존재하지 않습니다.</> :
                            list.admins.length > 0 ? list.admins.map((item) => {
                                return (
                                    <AButton domain={item.so} branch={item.shopBranch} key={item.so}/>
                                )
                            }) : <></>
                    }
                    {
                        staffList()
                    }
                </div>
            )
        } else if(list.admins.length === 6) {
            return (
                <div className="inputRoot listRoot">
                    {
                            list.admins.map((item) => {
                                return (
                                    <AButton domain={item.so} branch={item.shopBranch} key={item.so}/>
                                )
                            })
                    }
                </div>
            )
        } else {
            //6보다 크면 adimin
            const adminList = () => {
                let arr : JSX.Element[] = [];
                for(let i = 0 ; i < 6 ; i++) {
                    let aa = <AButton domain={list.admins[i].so} branch={list.admins[i].shopBranch} key={`${i}`}/>;
                    arr.push(aa);
                }
                return arr;
            }
            return  (
                <div className="inputRoot listRoot">
                    {
                        adminList()
                    }
                </div>
            )
        }
        return (
            <div className="inputRoot listRoot">
                {
                    list === emptyArr ? <>매장 리스트가 존재하지 않습니다.</> :
                        list.admins.length > 0 ? list.admins.map((item) => {
                            return (
                                <AButton domain={item.so} branch={item.shopBranch} key={item.so}/>
                            )
                        }) : <></>
                }
                {
                    list.staffs.length > 0 ? list.staffs.map((item) => {
                        return (
                            <AButton domain={item.so} branch={item.shopBranch} key={item.so}/>
                        )
                    }) : <></>
                }
                {
                    // arrList()
                }
            </div>
        )
    }
    else {
        return (
            <div className="inputRoot listRoot">
                {
                    list === emptyArr ? <>매장 리스트가 존재하지 않습니다.</> :
                        list.admins.length > 0 ? list.admins.map((item) => {
                            return (
                                <AButton domain={item.so} branch={item.shopBranch} key={item.so}/>
                            )
                        }) : <></>
                }
                {
                    list.staffs.length > 0 ? list.staffs.map((item) => {
                        return (
                            <AButton domain={item.so} branch={item.shopBranch} key={item.so}/>
                        )
                    }) : <></>
                }

            </div>
        )
    }

}

export default ABtnList;