import React from 'react';
import { XIcon } from '@heroicons/react/solid'

interface ModalType {
    modalClose:()=>void, // 리액트 props의 type을 알고 다시 수정하기
    size?: string,
    children:React.ReactNode,
    modalTitle?:string,
    anotherModal?:string
    modalBtn : JSX.Element,
    zIndex? : string
}

const Modal = ({modalClose, size="sm", children, zIndex ,modalTitle, anotherModal , modalBtn} : ModalType) => {
    return (
        <div className={`modalRoot ${anotherModal} ${zIndex}`}>
            <div className={`modal ${size}`}>
                <div className='modalhead'>
                    <div className='modalTitle'>
                        {modalTitle}
                    </div>
                    {/* <XIcon className="CloseBtn" onClick={modalClose}></XIcon> */}
                </div>
                <div className='modalContent'>
                    {children}
                    <div className='modalBtn flex items-center justify-center w-full'>
                        {modalBtn}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal;