import {useNavigate} from "react-router-dom";
import {AuthService, IProfile, LoginReq, TokenData} from "@/services/Auth.services";
import {ApiCommonError} from "@/utils/api";
import {useRecoilState, useResetRecoilState} from "recoil";
import {ShopProfileState, TokenDataState} from "@/recoil/UserAtoms";
import {useState} from "react";
import {encryptTest} from "@/utils/crypto/Crypto";
let code : string;
let data : string;
interface AuthType {
    id:string,
    pw:string
}
export const UseLogin = ({id,pw} : AuthType) => {
    const navigate = useNavigate();
    let [errorMsg, setErrorMsg] = useState('');
    let [idCondition, setidCondition] = useState<boolean>();
    let [pwCondition, setpwCondition] = useState<boolean>();
    let [token, setToken] = useRecoilState<TokenData>(TokenDataState);
    let ResetToken = useResetRecoilState(TokenDataState);
    const LoginAuth = async () => {
        code = "none";
        data = "";
        setidCondition(false);
        setpwCondition(false);
        setErrorMsg('');
        ResetToken();
        let idError = false;
        let pwError = false;
        if(id.length === 0) {
            setidCondition(true);
            idError = true;
        }
        if(pw.length === 0) {
            setpwCondition(true);
            pwError = true;
        }
        if (idError || pwError) {
            if (pwError && idError) {
                setErrorMsg("아이디와 비밀번호가 올바르지 않습니다");
                setpwCondition(true);
                setidCondition(true);
            } else if (pwError === false) {
                setErrorMsg("아이디가 올바르지 않습니다");
                setpwCondition(false);
                setidCondition(true);
            } else {
                setErrorMsg("비밀번호가 올바르지 않습니다");
                setpwCondition(true);
                setidCondition(false);
            }
            return;
        }
        // pw = encryptTest(pw,{iteration: 1000, iv: "dchyafqss1z0FkRMHRU67A==", keySize: 256, key: "C4Ed2YQGt/f3EbUYTDzdK5RFBCy29MPLbCUjOvMAxws=", salt: "u4zykyq5Xnw="})
        let req : LoginReq = { id:id, pwd:pw };
        let host = window.location.hostname;
        let Auth = new AuthService(null, host);
        try {
            let tokenResult = await Auth.login(req);
            code = tokenResult.code;
            data = JSON.stringify(tokenResult.data);
            // 응답을 리코일에 담는다.(tokenResult.Data)
            if(code === "SI000")
            {
                localStorage.clear();
                setToken(tokenResult.data);
                Auth.setCookies(data);

            } else if(code === "EIA001") {
                setErrorMsg('존재하지 않는 아이디입니다.');
            } else if(code === "EIA002") {
                setErrorMsg('비밀번호가 일치하지 않습니다');
            }
            else {
                // 의도하지 않은 오류
                window.alert("로그인이 실패하였습니다. 다시 시도해주세요");
            }
            return code;
        }
        catch(error) {
            // if (error instanceof ApiCommonError) {
            if (error instanceof ApiCommonError) {
                console.error(error);

            } else {
                console.error(error);
            }

        }
    }

    return {errorMsg,idCondition,pwCondition,code,LoginAuth};
}