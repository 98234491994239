interface ABtnType {
    domain:string,
    branch:string,
    disabled? : boolean
}
const AButton = ({domain,branch, disabled = false}:ABtnType) => {
    let host = localStorage.getItem("host");
    if(host === null) host = ".";
    const lastHostArr = host.split(".");
    return(
        <a className={`aBtn ${disabled ? "disabled" : ""}`} href={`https://${domain}.${lastHostArr[lastHostArr.length - 2]}.${lastHostArr[lastHostArr.length - 1]}`}>
            {
                disabled ? "" : branch
            }
        </a>
    );
}

export default AButton;