import {atom} from "recoil";
import {IProfile, Role, TokenData} from "../services/Auth.services";

export interface ShopType {
    ShopName: string,
    ShopImg: string,
    ShopCash: string,
    ShopMileage: string,
    ShopTest: string
}

export const ShopState = atom<ShopType>({
    key: 'Shop',
    default : {
        ShopName: "더스윙", 
        ShopImg : "",
        ShopCash : "800,000",
        ShopMileage : "100,000",
        ShopTest: "지점명 정보"
    },
});
export const ShopCode = atom<number>({
    key: 'shopCode',
    default : 0,
});
export const isSokectOpen = atom<boolean>({
    key: 'sokect',
    default : false,
});
export const ShopCash = atom<number>( {
    key: 'shopCash',
    default : 0,
})

export const TokenDataState = atom<TokenData>({
    key : 'TokenState',
    default : {
        token: "",
        refreshToken: "",
        created: new Date(),
        expires: new Date(),
        userId: "",
        code: 0

    },
});

export const ShopProfileState = atom<IProfile>({
    key : 'ShopRole',
    default : {
        id: "",
        name : "",
        role: Role.none, 
    },
});
