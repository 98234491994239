import React from "react";
import Footer from '../../layout/Footer';
import BigLogo from '../../assets/img/BigLogo.svg';
import {useRecoilValue, useResetRecoilState} from "recoil";
import {AuthService, TokenData} from "@/services/Auth.services";
import {TokenDataState} from "@/recoil/UserAtoms";
import {Navigate, useNavigate} from "react-router-dom";
import {UnauthorizedError} from "@/utils/api";
import LoginToken from "@/pages/Login/methods/LoginToken";
import ABtnList from "@/pages/Login/components/ABtnList";
import {UseSetShopList} from "@/pages/Login/UseSetShopList";
import {ShopList} from "@/pages/Login/interface";
const LoginList = () => {
const {host, resetToken, isCheckToken,navigate ,location, auth,cookie,login} = LoginToken();
const {shopList} = UseSetShopList();
// const arr :ShopList = {
//     "admins" : [
//         {
//         "so": "amind",
//         "shopBranch": "amind"
//     }, {
//         "so": "aa",
//         "shopBranch": "aa"
//     }, {
//         "so": "aa",
//         "shopBranch": "aa"
//     },
//         {
//             "so": "dd",
//             "shopBranch": "dd"
//         }, {
//             "so": "aa",
//             "shopBranch": "aa"
//         }, {
//             "so": "aa",
//             "shopBranch": "amind"
//         },
//         {
//             "so": "dd",
//             "shopBranch": "amind"
//         }, {
//             "so": "aa",
//             "shopBranch": "aa"
//         }, {
//             "so": "amind",
//             "shopBranch": "amind"
//         }
//     ],
//     "staffs" : [
//         {
//         "so": "amind",
//         "shopBranch": "asdasdsaddasdasd"
//     }, {
//         "so": "aa",
//         "shopBranch": "aa"
//     }
//         , {
//             "so": "aa",
//             "shopBranch": "aa"
//         }
//         , {
//             "so": "aa",
//             "shopBranch": "aa"
//         }
//         , {
//             "so": "aa",
//             "shopBranch": "aa"
//         }
//         , {
//             "so": "aa",
//             "shopBranch": "aa"
//         }
//
//     ]
// }
    try {
        if(host === "" || host === null || host !== location ) {
            localStorage.setItem("host", location);
        }
        if (isCheckToken === false) {
            if(cookie === undefined) {
                // console.log(cookie,"cookie in auth Required");
                resetToken();
                auth.remove();
                // debugger;
                return <Navigate to={login} replace/>
                // return navigate(login);
            }

        } else {
            console.count('마운트 해제 및 axios 요청 취소')
            // controller.abort()
            // navigate(`/login/list`,{replace:true})
        }
    } catch (e){
        if(e instanceof UnauthorizedError) {
            if (isCheckToken === false) {
                localStorage.clear();
                resetToken();
                auth.remove();
                return <Navigate to={login} replace/>
                // return navigate(login);
            }
        }
    }
    return(
        <div className="loginRoot">
            <div className="loginPage">
                <form id="loginForm">
                    <div className="LoginFormGroup">
                        <div className="logoBox">
                            <img src={BigLogo}/>
                        </div>
                        <ABtnList list={shopList}/>
                    </div>
                </form>
            </div>
            <Footer/>
        </div>
    );
};

export default LoginList;