import {useState} from "react";

export const LoginModal = () => {
    const [idPwModalOpen, setIdPwModal] = useState(false);
    const ModalOpen = () => {
        setIdPwModal(true);
    };
    const ModalClose = () => {
        setIdPwModal(false);
    };

    return {idPwModalOpen,ModalOpen,ModalClose};
}