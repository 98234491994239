import axios, {AxiosError, AxiosResponse, AxiosRequestConfig} from 'axios';
import Cookies from 'universal-cookie';
import {AuthService, TokenData} from "../../services/Auth.services"

export interface ApiResponse<T> {
    msg: string,
    code: string,
    status: string,
    data: T
}

interface AxiosCustomRequestConfig extends AxiosRequestConfig {
    retryCount: number
}

const MAX_RETRY_COUNT = 3;
const apiUrl = process.env.REACT_APP_API_URL;


let cookies = new Cookies();

class ApiHelper {
    private static instance: ApiHelper;
    public TOKEN = ''
    public REFRESH = ''

    constructor(token: string = '', refresh: string = '') {
        if (token !== '') this.TOKEN = token;
        if (refresh !== '') this.REFRESH = refresh;
        let cookie = cookies.get("serial");
        if (token === '' && cookie !== "" && cookie !== undefined) {
            // console.log(cookie,"coolie");
            this.TOKEN = cookie.token;
            this.REFRESH = cookie.refreshToken;
        }
        // axios.defaults.headers.common = {'Authorization': `bearer ${token}`};

    }

    public static getInstance() {
        return this.instance || (this.instance = new this())
    }

    public getHost() {
        const host = localStorage.getItem('host');
        if (host === null) return "";
        return host;
        // return "sohong.cokeetest.io";
        //TODO 풀호스트인 걸 교체해야함
    }

    public setToken(token: string) {
        this.TOKEN = token;
    }

    public setRefresh(refresh: string) {
        this.REFRESH = refresh;
    }

    public async get<T = any>(url: string): Promise<ApiResponse<T>> {
        let result: ApiResponse<T> = {
            msg: '',
            code: "E0",
            status: 'FAIL',
            data: {} as T // 실제 런타임에선 object로 인지,
        };
        const config = {
            headers: {
                'Content-Type': 'Application/json',
                'Authorization': `bearer ${this.TOKEN}`,
                // 'Host' : this.getHost(),
                'timeout': "1000",
            }

        }
        // axios.interceptors.response.use((aa)=>{console.log(aa)},()=>{})
        axios.interceptors.response.use((response) => {
            return response;
        }, async (error: AxiosError) => {
            const {config, message} = error;
            if (error.response) {
                if (error.response.status === 401) {
                    const result = await axios.post<ApiResponse<TokenData>>(apiUrl + "auth/user/refresh", {"RefreshToken": this.REFRESH}, {
                        headers: {
                            'Content-Type': 'Application/json',
                            'Authorization': `bearer ${this.TOKEN}`,
                            // 'Host' : this.getHost(),
                            'timeout': "1000",
                        }
                    })
                    const data: TokenData = result.data.data;
                    const axiosInstance = axios.create({
                        url: apiUrl + url,
                        headers: {
                            'Content-Type': 'Application/json',
                            'Authorization': `bearer ${data.token}`,
                            // 'Host' : this.getHost(),
                            'timeout': "1000",
                        }
                    });
                    const originalRequest = error.config;
                    const auth = new AuthService(data, this.getHost());
                    this.setRefresh(data.refreshToken);
                    this.setToken(data.token);
                    auth.setCurrentUser(data);
                    setTimeout(() => {
                        return axiosInstance(originalRequest);
                    }, 3000);
                }
                return Promise.reject(error);
            }

        })
        await axios.get<ApiResponse<T>>(apiUrl + url, config)
            .then(response => {
                let data: ApiResponse<T> = typeof response.data === "string" ?
                    response.data as ApiResponse<T> : response.data;
                result = data;
            })
            .catch((error: AxiosError) => {
                if (error.response) {
                    if (error.response!.status === 400) {
                        throw new BadRequestError("유효하지 않은 요청입니다.");
                    } else if (error.response!.status === 401) {
                        this.handleUnauthorizedError()
                        throw new UnauthorizedError("로그인 유효 시간이 지났습니다. 로그인 페이지로 돌아갑니다.");
                    } else if (error.response!.status === 403) {
                        throw new ForbiddenError("접근 권한이 없습니다.");
                    } else if (error.response!.status === 404) {
                        throw new NotFoundError("존재하지 않는 페이지입니다.");
                    } else if (error.request) {
                        // 요청이 이루어졌지만 응답을 받지 못했을 때의 구문
                        throw new timeOutError("응답이 없습니다.");
                    } else {
                        console.error(`[API - POST] 의도하지 않은 에러 발생 : ${error.message}`);
                    }
                    // console.log(error);

                    return result;
                }
            });
        return result;
    }

    public async post<T = any>(url: string, body: any, contentType: string = "Application/json"): Promise<ApiResponse<T>> {
        let result: ApiResponse<T> = {
            msg: '',
            code: "E0",
            status: 'FAIL',
            data: {} as T // 실제 런타임에선 object로 인지
        };
        const config = {
            headers: {
                'Content-Type': contentType,
                'Authorization': `bearer ${this.TOKEN}`,
                // 'validateStatus': function (status:any) {
                //     return status >=
                //
                //         200 && status <300;
                // }
                // 'Host' : this.getHost(),
            }
        }
        let stringBody = JSON.stringify(body);
        axios.interceptors.response.use((response) => {
            return response;
        }, async (error: AxiosError) => {
            const config = error.config as AxiosCustomRequestConfig;
            // console.log(config,"config");
            if (error.response) {
                if (error.response.status === 401) {
                    const result = await axios.post<ApiResponse<TokenData>>(apiUrl + "auth/user/refresh", {"RefreshToken": this.REFRESH}, {
                        headers: {
                            'Content-Type': 'Application/json',
                            'Authorization': `bearer ${this.TOKEN}`,
                            // 'Host' : this.getHost(),
                            'timeout': "1000",
                        }
                    })
                    const data: TokenData = result.data.data;
                    const axiosInstance = axios.create({
                        url: apiUrl + url,
                        headers: {
                            'Content-Type': 'Application/json',
                            'Authorization': `bearer ${data.token}`,
                            // 'Host' : this.getHost(),
                            'timeout': "1000",
                        }
                    });
                    const originalRequest = error.config;
                    const auth = new AuthService(data, this.getHost());
                    this.setRefresh(data.refreshToken);
                    this.setToken(data.token);
                    auth.setCurrentUser(data);
                    setTimeout(() => {
                        return axiosInstance(originalRequest);
                    }, 3000);
                }
                return Promise.reject(error);
            }
        })
        await axios
            .post<ApiResponse<T>>(apiUrl + url, stringBody, config)
            //TODO 기존의 post는 로그인 인증으로만 사용되어서 타석관리를 보내는 url를 추가할수있는 방법을 생각해야함
            // .then((response)=> {
            //     let data : ApiResponse<T> = typeof response.data === "string" ?
            //         response.data = ApiResponse : response.data;
            //     result = data;
            // })
            .then((response) => {
                // console.log(response,"response");
                let data: ApiResponse<T> = typeof response.data === "string" ?
                    response.data as ApiResponse<T> : response.data;
                result = data;
            })
            .catch((error: AxiosError<string>) => {
                if (error.response) {
                    if (error.response.status === 400) {
                        throw new BadRequestError("유효하지 않은 요청입니다.");
                    } else if (error.response.status === 401) {
                        this.handleUnauthorizedError()
                        throw new UnauthorizedError("로그인 유효 시간이 지났습니다. 로그인 페이지로 돌아갑니다.");
                    } else if (error.response.status === 403) {
                        throw new ForbiddenError("접근 권한이 없습니다.");
                    } else if (error.response.status === 404) {
                        throw new NotFoundError("존재하지 않는 페이지입니다.");
                    } else if (error.request) {
                        // 요청이 이루어졌지만 응답을 받지 못했을 때의 구문

                    } else {
                        console.error(`[API - POST] 의도하지 않은 에러 발생 : ${error.message}`);
                    }
                }

                return result;
            });
        return result;
    }

    public async put<T = any>(url: string, body: any): Promise<ApiResponse<T>> {
        // TODO : 함수 구현부가 없어 post 를 기준으로 함수 인용 (추후 수정 필요)
        // post와 put의 차이점에 대해 검색해보았는데 멱등성과 리소스 결정권에 따라 두 로직을 구별하여 사용한다고 한다.
        // url을 서버에게 결정권을 넘길 때 post 클라이언트가 정해야할때 put이라고 함
        // 그럼 악시오스에서 내가 보내야하는 url변동은 어떻게 해야하지?
        let result: ApiResponse<T> = {
            msg: '',
            code: "E0",
            status: 'FAIL',
            data: {} as T // 실제 런타임에선 object로 인지
        };

        const config = {
            headers: {
                'Content-Type': 'Application/json',
                'Authorization': `bearer ${this.TOKEN}`,
                // 'Host' : this.getHost(),
            }
        }
        let stringBody = JSON.stringify(body);
        // console.log(this.TOKEN);
        axios.interceptors.request.use(undefined, async (error: AxiosError) => {
            const config = error.config as AxiosCustomRequestConfig
            if (error.response!.status === 401) {
                const result = await axios.post<ApiResponse<TokenData>>(apiUrl + "auth/user/refresh", {"RefreshToken": this.REFRESH}, {
                    headers: {
                        'Content-Type': 'Application/json',
                        'Authorization': `bearer ${this.TOKEN}`,
                        // 'Host' : this.getHost(),
                        'timeout': "1000",
                    }
                })
                if (result !== undefined) {
                    const data: TokenData = result.data.data;
                    // console.log(result.data , "result.data");
                    const auth = new AuthService(data, this.getHost());
                    this.setRefresh(data.refreshToken);
                    this.setToken(data.token);
                    auth.setCurrentUser(data);
                }
                return;
            }
        })
        await axios
            .put<ApiResponse<T>>(apiUrl + url, stringBody, config)
            .then(response => {
                let data: ApiResponse<T> = typeof response.data === "string" ?
                    response.data as ApiResponse<T> : response.data;
                result = data;
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    throw new BadRequestError("유효하지 않은 요청입니다.");
                } else if (error.response.status === 401) {
                    this.handleUnauthorizedError()
                    throw new UnauthorizedError("로그인 유효 시간이 지났습니다. 로그인 페이지로 돌아갑니다.");
                } else if (error.response.status === 403) {
                    throw new ForbiddenError("접근 권한이 없습니다.");
                } else if (error.response.status === 404) {
                    throw new NotFoundError("존재하지 않는 페이지입니다.");
                } else if (error.request) {
                    // 요청이 이루어졌지만 응답을 받지 못했을 때의 구문

                } else {
                    // console.error(`[API - POST] 의도하지 않은 에러 발생 : ${error.message}`);
                }
                // console.log(error);

                return result;
            });
        return result;
    }

    public async delete<T = any>(url: string): Promise<ApiResponse<T>> {
        let result: ApiResponse<T> = {
            msg: '',
            code: "E0",
            status: 'FAIL',
            data: {} as T
        };

        const config = {
            headers: {
                'Content-Type': 'Application/json',
                'Authorization': `bearer ${this.TOKEN}`,
            }
        }
        axios.interceptors.request.use(undefined, async (error: AxiosError) => {
            const config = error.config as AxiosCustomRequestConfig
            if (error.response!.status === 401) {
                const result = await axios.post<ApiResponse<TokenData>>(apiUrl + "auth/user/refresh", {"RefreshToken": this.REFRESH}, {
                    headers: {
                        'Content-Type': 'Application/json',
                        'Authorization': `bearer ${this.TOKEN}`,
                        // 'Host' : this.getHost(),
                        'timeout': "1000",
                    }
                })
                if (result !== undefined) {
                    const data: TokenData = result.data.data;
                    // console.log(result.data , "result.data");
                    const auth = new AuthService(data, this.getHost());
                    this.setRefresh(data.refreshToken);
                    this.setToken(data.token);
                    auth.setCurrentUser(data);
                }
                return;
            }
        })
        await axios
            .delete<ApiResponse<T>>(apiUrl + url, config)
            .then(response => {
                let data: ApiResponse<T> = typeof response.data === "string" ?
                    response.data as ApiResponse<T> : response.data;
                result = data;
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    throw new BadRequestError("유효하지 않은 요청입니다.");
                } else if (error.response.status === 401) {
                    this.handleUnauthorizedError();
                    throw new UnauthorizedError("로그인 유효 시간이 지났습니다. 로그인 페이지로 돌아갑니다.");
                } else if (error.response.status === 403) {
                    throw new ForbiddenError("접근 권한이 없습니다.");
                } else if (error.response.status === 404) {
                    throw new NotFoundError("존재하지 않는 페이지입니다.");
                } else if (error.request) {
                    let req = error.response.data;
                    // console.log(error.response);
                    // console.log(req);
                    throw new RequestError(req.msg, req.code);
                } else {
                    // console.error(`[API - POST] 의도하지 않은 에러 발생 : ${error.message}`);
                }
                // console.log(error);

                return result;
            });
        return result;
    }

    public async postWithFormData<T = any>(url: string, data: FormData): Promise<ApiResponse<T>> {
        let result: ApiResponse<T> = {
            msg: '',
            code: "E0",
            status: 'FAIL',
            data: {} as T
        };
        const config = {
            headers: {
                'Content-Type': "multipart/form-data",
                'Authorization': `bearer ${this.TOKEN}`,
            }
        }
        const destinationUrl = apiUrl + url
        axios.interceptors.request.use(undefined, async (error: AxiosError) => {
            const config = error.config as AxiosCustomRequestConfig
            if (error.response) {
                if (error.response.status === 401) {
                    const result = await axios.post<ApiResponse<TokenData>>(apiUrl + "auth/user/refresh", {"RefreshToken": this.REFRESH}, {
                        headers: {
                            'Content-Type': 'Application/json',
                            'Authorization': `bearer ${this.TOKEN}`,
                            // 'Host' : this.getHost(),
                            'timeout': "1000",
                        }
                    })
                    if (result !== undefined) {
                        const data: TokenData = result.data.data;
                        // console.log(result.data , "result.data");
                        const auth = new AuthService(data, this.getHost());
                        this.setRefresh(data.refreshToken);
                        this.setToken(data.token);
                        auth.setCurrentUser(data);
                    }
                    return;
                }
            }

        })
        await axios.post<ApiResponse<T>>(destinationUrl, data, config)
            .then(response => {
                let data: ApiResponse<T> = typeof response.data === "string" ?
                    response.data as ApiResponse<T> : response.data;
                result = data;
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    throw new BadRequestError("유효하지 않은 요청입니다.");
                } else if (error.response.status === 401) {
                    this.handleUnauthorizedError()
                } else if (error.response.status === 403) {
                    throw new ForbiddenError("접근 권한이 없습니다.");
                } else if (error.response.status === 404) {
                    throw new NotFoundError("존재하지 않는 페이지입니다.");
                } else if (error.request) {
                    // 요청이 이루어졌지만 응답을 받지 못했을 때의 구문
                    throw new timeOutError("응답이 없습니다.");
                } else {
                    // console.error(`[API - POST] 의도하지 않은 에러 발생 : ${error.message}`);
                }
                // console.log(error);

                return result;
            });
        return result;
    }

    public async putWithFormData<T = any>(url: string, data: FormData): Promise<ApiResponse<T>> {
        let result: ApiResponse<T> = {
            msg: '',
            code: "E0",
            status: 'FAIL',
            data: {} as T
        };
        const config = {
            headers: {
                'Content-Type': "multipart/form-data",
                'Authorization': `bearer ${this.TOKEN}`,
            }
        }
        const destinationUrl = apiUrl + url
        axios.interceptors.request.use(undefined, async (error: AxiosError) => {
            const config = error.config as AxiosCustomRequestConfig
            if (error.response!.status === 401) {
                const result = await axios.post<ApiResponse<TokenData>>(apiUrl + "auth/user/refresh", {"RefreshToken": this.REFRESH}, {
                    headers: {
                        'Content-Type': 'Application/json',
                        'Authorization': `bearer ${this.TOKEN}`,
                        // 'Host' : this.getHost(),
                        'timeout': "1000",
                    }
                })
                if (result !== undefined) {
                    const data: TokenData = result.data.data;
                    // console.log(result.data , "result.data");
                    const auth = new AuthService(data, this.getHost());
                    this.setRefresh(data.refreshToken);
                    this.setToken(data.token);
                    auth.setCurrentUser(data);
                }
                return;
            }
        })
        await axios.put<ApiResponse<T>>(destinationUrl, data, config)
            .then(response => {
                let data: ApiResponse<T> = typeof response.data === "string" ?
                    response.data as ApiResponse<T> : response.data;
                result = data;
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    throw new BadRequestError("유효하지 않은 요청입니다.");
                } else if (error.response.status === 401) {
                    this.handleUnauthorizedError()
                } else if (error.response.status === 403) {
                    throw new ForbiddenError("접근 권한이 없습니다.");
                } else if (error.response.status === 404) {
                    throw new NotFoundError("존재하지 않는 페이지입니다.");
                } else if (error.request) {
                    // 요청이 이루어졌지만 응답을 받지 못했을 때의 구문
                    let req = error.response;
                    throw new RequestError(req.message, req.code);
                } else {
                    console.error(`[API - POST] 의도하지 않은 에러 발생 : ${error.message}`);
                }
                // console.log(error);

                return result;
            });
        return result;
    }

    private handleUnauthorizedError = () => {
        let cookie = cookies.get("serial");
        // console.log("API COOKIE : " , cookie);
    }

}

// 각각의 에러 컨트롤에 대한 함수

export interface ApiError extends Error {
    status: number;
}

export class ApiCommonError implements ApiError {
    public name: string;
    public message: string;
    public status: number;

    constructor(name: string, message: string, status: number) {
        this.name = name; //name;
        this.message = message;
        this.status = status;
    }
}

// 404
export class NotFoundError extends ApiCommonError {

    constructor(message: string) {
        let name = NotFoundError.name;
        super(name, message, 404);

    }
}

// 408
export class timeOutError extends ApiCommonError {

    constructor(message: string) {
        let name = timeOutError.name;
        super(name, message, 408);

    }
}

// let test = new NotFoundError("name", "message");

// 403
export class ForbiddenError extends ApiCommonError {

    constructor(message: string) {
        let name = ForbiddenError.name;
        super(name, message, 403);

    }
}

// 400
export class BadRequestError extends ApiCommonError {
    constructor(message: string) {
        let name = BadRequestError.name;
        super(name, message, 400);

    }
}

export class RequestError extends ApiCommonError {
    constructor(message: string, errorCode: number) {
        let name = RequestError.name;
        super(name, message, errorCode);

    }

}

// 401
export class UnauthorizedError extends ApiCommonError {
    constructor(message: string) {
        let name = UnauthorizedError.name;
        super(name, message, 401);

    }

}

const Api = ApiHelper.getInstance();
export default Api;