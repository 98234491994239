import {useEffect, useState} from "react";
import {ShopList} from "@/pages/Login/interface";
import {GetShopList} from "@/pages/Login/services/GetShopList";

export const UseSetShopList = () => {
    const [shopList , setShopList] = useState<ShopList>({admins: [], staffs: []});
    const getApi = async () => {
        let value = await GetShopList();
        if (value === null || value === undefined) {
            value = {admins: [], staffs: []};
        }
        return {value};
    }
    const fetchData = async () => {
        try {
            const api = await getApi();
            let isValid = api !== undefined && api !== null;
            if(isValid) {
                setShopList(api.value);
            } else {
                setShopList({admins: [], staffs: []});
            }

        } catch (e) {

        }
    }
    useEffect(()=>{
        fetchData();
    },[])
    return {shopList};
}