import React from "react"; 
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Login from "./pages/Login/Login";
import  "./utils/FontAwesome";

import "../src/assets/css/style.css";
import "../src/assets/css/index.css";
import { RecoilRoot } from "recoil";
import LoginList from "./pages/Login/Login.List";

{/* TODO : 모든페이지에 loadable 적용 https://loadable-components.com/ */}
function App() {
  return (
    <RecoilRoot>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Login/>}/>
            <Route path="/login" element={<Login/>}></Route>
            <Route path="/login/list" element={<LoginList/>}/>
            <Route path="*" element={<>404</>}/>
          </Routes>
        </BrowserRouter>
      </div>
    </RecoilRoot>
  );
}

export default App;
