import {useRecoilValue, useResetRecoilState} from "recoil";
import {AuthService, TokenData} from "@/services/Auth.services";
import {TokenDataState} from "@/recoil/UserAtoms";
import {useNavigate} from "react-router-dom";
import Cookies from "universal-cookie";

const LoginToken = () => {
    const login = '/login';
    const navigate = useNavigate();
    const host = localStorage.getItem("host");
    const userRole = localStorage.getItem("userRole");
    // const token = useRecoilValue<TokenData>(TokenDataState);
    const resetToken = useResetRecoilState(TokenDataState);
    const location = window.location.hostname;
    let cookies = new Cookies();
    const token = cookies.get("serial");
    const auth = new AuthService(token, location);
    const isCheckToken = auth.checkToken();
    const cookie = auth.getCookies();
    return {host, resetToken, isCheckToken,navigate ,location, auth,login,cookie};
}

export default LoginToken;