const Footer = () => {
    let FooterUlClass = "";
    let FooterLiClass = "";
    return (
        <footer>
            <div>
                <ul className={FooterUlClass}>
                    <li className={FooterLiClass}>
                        <a href="#">TMS이용약관</a>
                        <span className="">|</span>
                        </li>
                    <li>
                        <a href="#">개인정보처리방침</a>
                    </li>
                </ul>
            </div>
            <div className="mt-[9px]">
                <ul className={FooterUlClass}>
                    <li className={FooterLiClass}>
                        대표 : 가가가
                        <span className="">|</span>
                    </li>
                    <li>
                        주소 : 가가가가가가가가 (주) 가가
                    </li>
                </ul>
            </div>
            <div className="">
                <ul className={FooterUlClass}>
                    <li className={FooterLiClass}>
                        대표전화 : 1234-1234
                        <span className="">|</span>
                    </li>
                    <li>
                        팩스 : 02-1234-1234
                        <span className="">|</span>
                    </li>
                    <li>
                        사업자등록번호 : 123-45-6789
                    </li>
                </ul>
            </div>
            <div className="">
                <ul className={FooterUlClass}>
                    <li className={FooterLiClass}>
                        Copyright ⓒ aaaaaa All Rights Reserved.*
                    </li>
                </ul>
            </div>
        </footer>
    );
};

export default Footer;