import React, { ChangeEventHandler } from "react";

interface InputType {
    id : string,
    name : string,
    value? : string,
    type? : string,
    placeholder : string,
    onchange?: ChangeEventHandler<HTMLInputElement> | undefined,
    size? : string,
    variant? : string,
    disabled?: boolean,
    errorMassage? : string
    icon? : JSX.Element,
    onSubmit? : (e:any)=>void,
    readOnly?:boolean
}
const InputComponent = ({id , name , value , type = "text" , onSubmit , placeholder = "" , onchange,readOnly,size = "sm", variant = 'default' , disabled , errorMassage, icon} : InputType) => {
    window.addEventListener("keydown", (event) => {
        if (event.defaultPrevented) {
            console.log(event.defaultPrevented);
            return; // Should do nothing if the default action has been cancelled
        }

        let handled = false;
        if (event.key !== undefined) {
            // Handle the event with KeyboardEvent.key
            if(event.key === "Enter") {
                if(onSubmit === undefined) return;
                onSubmit(event);

            }
            handled = true;
        } else if (event.keyCode !== undefined) {
            // Handle the event with KeyboardEvent.keyCode
            handled = true;
        }
    }, true);
    return(
        <div className="inputWraps flex w-full relative">
            {icon}
            <input
                id={id}
                name={name}
                defaultValue={value}
                type={type}
                placeholder={placeholder}
                disabled={disabled}
                onChange={onchange}
                onSubmit={onSubmit}
                readOnly={readOnly}
                className={`w-full outline-none input ${size} ${variant} ${disabled ? 'disabled' : ''} ${icon !== undefined ? "!pl-[55px]" : ""}`}
            />
            <span className={`error-massage + ${variant === "error" ? "" : ""}`}>{errorMassage}</span>
        </div>
    )
};

export default InputComponent;